import React from "react";
import {
  Heading,
  Box,
  Flex,
  Text,
} from "@chakra-ui/react";
import Img1 from '../Logo/1.webp';
import Img2 from '../Logo/2.webp';
import Img3 from '../Logo/3.webp';
import Img4 from '../assets/4.webp';
import Img5 from '../Logo/5.webp';
import Img6 from '../assets/6.webp';
import Img7 from '../Logo/7.webp';
import Img8 from '../Logo/8.webp';
import Img9 from '../assets/9.webp';
import Img10 from '../Logo/10.webp';
import Img11 from '../Logo/11.webp';
import Img12 from '../assets/12.webp';
import Img13 from '../assets/13.webp';
import Img14 from '../Logo/14.webp';
import Img15 from '../assets/15.webp';
import Img16 from '../Logo/16.webp';
import Img17 from '../Logo/17.webp';
import Img18 from '../Logo/18.webp';


const Client = () => {
  return (
   
    <Box bg="black" color="white" mt={{base:'3%', sm:'3%', md:'0px'}}>  
        <Flex justify="center">
          <Heading as="h2" fontSize={{ xl: '24', lg: '24', md: '26', sm: '22', base: '22' }} mb={10}>
          <Text as='span' fontWeight='200' fontSize={{ xl: '24', lg: '24', md: '20', sm: '22', base: '22' }}>Ou</Text>
            <Text as='span' fontFamily='"Pixelify Sans", sans-serif !important' fontSize={{ xl: '32', lg: '32', md: '32', sm: '30', base: '30' }} color='#ff5f35'>r{' '}</Text>
            Clients
          </Heading>
        </Flex>
        
        <div className="logos">
            <div className="logos-slide">
              <img className="" src={Img1}  alt='logo not found'  />
              <img className="" src={Img2} alt='logo not found' />
              <img className="" src={Img3} alt='logo not found' />
              <img className="" src={Img4} alt='logo not found' />
              <img className="" src={Img5} alt='logo not found' />
              <img className="" src={Img18} alt='logo not found' />
              <img className="" src={Img12} alt='logo not found' />
              <img className="" src={Img13} alt='logo not found' />
              <img className="" src={Img14} alt='logo not found' />

            </div>

          <div className="logos-slide">
            {/* <img className="" src={Img6} alt='logo not found' /> */}
            <img className="" src={Img6} alt='logo not found' />
            <img className="" src={Img7} alt='logo not found' />
            <img className="" src={Img8} alt='logo not found' />
            <img className="" src={Img9} alt='logo not found' />
            <img className="" src={Img10} alt='logo not found' />
            <img className="" src={Img11} alt='logo not found' />
            <img className="" src={Img15} alt='logo not found' />
            <img className="" src={Img16} alt='logo not found' />
            <img className="" src={Img17} alt='logo not found' />
        
          </div>
        </div>
        {/* <Grid
          templateColumns={{
            base: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
          }}
          gap={4}
          mx={{base: '4%', sm: '8%'}}
          mt='40px'
          pb='60px'
        >
        
            <GridItem
              bg=""
              w="100%"
              h="base: 300"
              display={{
                base: "block",
                sm: "block",
                lg: "block",
                md: "block",
                xl: "block",
              }}
              mb={5}
            >
            

              <Text textAlign={{base:'center', sm:'left'}} fontSize={22} color="#fff">
                What People Say
              </Text>
              <Text fontSize={22} textAlign={{base:'center', sm:'left'}} fontWeight="bold" color="#fff">
                Ab
                <Text as='span' fontFamily='"Pixelify Sans", sans-serif !important' fontSize='30' color='#ff5f35'>o</Text>
                ut Us
              </Text>
           
            </GridItem>
       
          <motion.div
            initial={{ opacity: 0, y: 200 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.5 }}
          >
            <GridItem
              bg="#262626"
              w="100%"
              h='100%'
              display={{
                base: "block",
                sm: "block",
                lg: "block",
                md: "block",
                xl: "block",
              }}
              p={4}
            >
              <Flex>
                <Box p={2}>
                  <Text fontSize={{ base: 17, sm: 17, md: 22, lg: 22, xl: 22 }}>Harshal Joshi</Text>
                </Box>
              </Flex>
              <Flex>
                <Box p={2}>
                  <Text letterSpacing='1px' fontSize={{ base: 13, sm: 11, md: 11, lg: 11, xl: 13 }}>
                    Marworx has been an invaluable partner in propelling our
                    digital presence forward. Their innovative strategies and
                    dedication to excellence have significantly boosted our brand
                    visibility and engagement. With Marworx, we've witnessed
                    remarkable growth and are excited to continue our journey
                    together.
                  </Text>
                </Box>
              </Flex>

              <Flex justify="flex-end">
                <SimpleGrid
                  columns={{ base: 6, sm: 6, lg: 6, md: 6, xl: 6 }}
                  spacing={1}
                  mt={{ base: 15, sm: 2, md: 3, lg: 5 }}
                >
                  <Image src={Star} alt="Client 1" />
                  <Image src={Star} alt="Client 2" />
                  <Image src={Star} alt="Client 3" />
                  <Image src={Star} alt="Client 4" />
                  <Image src={Star} alt="Client 5" />
                </SimpleGrid>
              </Flex>
            </GridItem>
          </motion.div>
       
          <motion.div
            initial={{ opacity: 0, y: 200 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.5 }}
          >
            <GridItem
              bg="#262626"
              w="100%"
              h='100%'
              display={{
                base: "block",
                sm: "block",
                lg: "block",
                md: "block",
                xl: "block",
              }}
              p={4}
            >
              <Flex>
                <Box p={2}>
                  <Text fontSize={{ base: 17, sm: 17, md: 22, lg: 22, xl: 22 }}>Rishabh Morde</Text>
                </Box>
              </Flex>
              <Flex>
                <Box p={2}>
                  <Text letterSpacing='1px' fontSize={{ base: 13, sm: 10, md: 10, lg: 10, xl: 12 }}>
                    Working with Marworx has been a game-changer for our business.
                    Their team's expertise in digital marketing has helped us
                    reach new heights and exceed our goals. Their commitment to
                    understanding our unique needs and delivering customised
                    solutions sete them apart. We highly recommend Marworx to any
                    organization looking to maximize their online impact
                  </Text>
                </Box>
              </Flex>
              <Flex justify="flex-end">
                <SimpleGrid
                  columns={{ base: 6, sm: 6, lg: 6, md: 6, xl: 6 }}
                  spacing={1}
                  mt={{ base: 15, sm: 3, md: 5, lg: 5, xl: 4 }}
                >
                  <Image src={Star} alt="Client 1" />
                  <Image src={Star} alt="Client 2" />
                  <Image src={Star} alt="Client 3" />
                  <Image src={Star} alt="Client 4" />
                  <Image src={Star} alt="Client 5" />
                </SimpleGrid>
              </Flex>
            </GridItem>
          </motion.div>
       
          <GridItem
            bg=""
            w="100%"
            h='100%'
            display={{
              base: "none",
              sm: "none",
              lg: "block",
              md: "none",
              xl: "block",
            }}
          >
         
          </GridItem>
       
          <GridItem
            bg=""
            w="100%"
            h='100%'
            display={{
              base: "none",
              sm: "none",
              lg: "block",
              md: "none",
              xl: "block",
            }}
          >
         
          </GridItem>
      
          <GridItem
            bg=""
            w="100%"
            h='100%'
            display={{
              base: "none",
              sm: "none",
              lg: "block",
              md: "none",
              xl: "block",
            }}
          >
            
          </GridItem>
      
          <motion.div
            initial={{ opacity: 0, y: 200 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.5 }}
          >

            <GridItem
              bg="#262626"
              w="100%"
              h='100%'
              display={{
                base: "block",
                sm: "block",
                lg: "block",
                md: "block",
                xl: "block",
              }}
              p={4}
            >
              <Flex>
                <Box p={2}>
                  <Text fontSize={{ base: 17, sm: 17, md: 22, lg: 22, xl: 22 }}>Simon Sable</Text>
                </Box>
              </Flex>
              <Flex>
                <Box p={2}>
                  <Text letterSpacing='1px' fontSize={{ base: 13, sm: 11, md: 11, lg: 11, xl: 13 }}>
                    Marworx has been an invaluable partner in propelling our digital presence forward.
                    Their innovative strategies and dedication to excellence have significantly boosted our brand visibility and engagement.
                    With Marworx, we've witnessed remarkable growth and are excited to continue our journey together.
                  </Text>
                </Box>
              </Flex>
              <Flex justify="flex-end">
                <SimpleGrid
                  columns={{ base: 6, sm: 6, lg: 6, md: 6, xl: 6 }}
                  spacing={1}
                  mt={{ base: 15, sm: 2, md: 3, lg: 5 }}
                >
                  <Image src={Star} alt="Client 1" />
                  <Image src={Star} alt="Client 2" />
                  <Image src={Star} alt="Client 3" />
                  <Image src={Star} alt="Client 4" />
                  <Image src={Star} alt="Client 5" />
                </SimpleGrid>
              </Flex>
            </GridItem>
          </motion.div>
       
          <motion.div
            initial={{ opacity: 0, y: 200 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.5 }}
          >
            <GridItem
              bg="#262626"
              w="100%"
              h='100%'
              display={{
                base: "block",
                sm: "block",
                lg: "block",
                md: "block",
                xl: "block",
              }}
              p={4}
            >
              <Flex>
                <Box p={2}>
                  <Text fontSize={{ base:17, sm:17, md: 22, lg: 22, xl: 22 }}>Richa Kalbhor</Text>
                </Box>
              </Flex>
              <Flex>
                <Box p={2}>
                  <Text letterSpacing='1px' fontSize={{ base: 13, sm: 10, md: 10, lg: 10, xl: 12 }}>
                    Working with Marworx has been game-changer for our business.
                    Their team's expertise in digital marketing has helped us reach new heights and exceed our goals.
                    Their commitment to understanding our unique needs and delivering customised solutions sets them apart.
                    We highly recommend Marworx to any organization looking to maximize their online impact
                  </Text>
                </Box>
              </Flex>
              <Flex justify="flex-end">
                <SimpleGrid
                  columns={{ base: 6, sm: 6, md: 5, lg: 6, xl: 6 }}
                  spacing={1}
                  mt={{ base: 15, sm: 3, md: 6, lg: 5, xl: 8 }}
                >
                  <Image src={Star} alt="Client 1" />
                  <Image src={Star} alt="Client 2" />
                  <Image src={Star} alt="Client 3" />
                  <Image src={Star} alt="Client 4" />
                  <Image src={Star} alt="Client 5" />
                </SimpleGrid>
              </Flex>
            </GridItem>
          </motion.div>
        </Grid>
 */}


      {/* </Container> */}
    </Box>
  );
};

export default Client;
